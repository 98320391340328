.login_page_container {
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, #2d2d2d 0%, rgba(45, 45, 45, 0.8) 100%);
    background-position: center center;
    display: grid;
    place-items: center;
}

.login_content {
    background-color: #fff;
    max-width: 850px;
    margin-inline: auto;
    border-radius: 1rem;
}

.login_right_img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
}

.login_left {
    padding: 2rem;
    height: 100%;
}

.logo_container {
    display: grid;
    place-items: center;
}

.logo {
    width: 9rem;
}

.login_form {
    margin-top: 1rem;
}

.form_title {
    text-align: center;
    font-size: 1.8rem;
    margin-top: 1rem;
}

.bottom_links {
    display: flex;
    justify-content: space-between;

    a {
        color: #9ca8b3;
    }
}
