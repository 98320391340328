.card {
  background-color: #efedec;
  padding: 1rem 1.5rem;
  border-radius: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.card_icon {
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  background-color: #2e325c;
  display: grid;
  place-items: center;
}

.card_title {
  padding: 1rem 0 0 0;
  font-size: 1.3rem;
  text-transform: capitalize;
  font-weight: bold;
  color: #2e325c;
}

.card_description {
  color: #706e77;
  font-weight: lighter;
  font-size: 1.2rem;
}

.card_amount {
  font-size: large;
  text-transform: uppercase;
  font-weight: bold;
  color: #2e325c;
}

.countdown {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.deposit_withdraw_form {
  padding-top: 1.5rem;
}

.form_buttons {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  padding-top: 1rem;
}

.personal_stat_wrapper {
  padding-top: 1.7rem;
}

.personal_stat_title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 1rem;
}

.personal_stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.8rem;
  background-color: #fff;
  border-radius: 5px;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.recent_transactions {
  margin-top: 2rem;

  @media (max-width: 36em) {
    margin-bottom: 3rem;
    margin-top: 4rem;
  }
}

.recent_transactions_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    font-size: 1.7rem;
    font-weight: bold;

    @media (max-width: 24.0625em) {
      font-size: 1.4rem;
    }
  }
}

.table_wrapper {
  @media (max-width: 36em) {
    overflow-x: scroll;
    white-space: nowrap;
  }
}

.table_item {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
  grid-gap: 1rem;
  padding: 1rem 0.3rem;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
    @media (max-width: 36em) {
      width: fit-content;
    }
  }
}

.transaction_amount,
.transaction_type {
  font-weight: bold;
  color: #2e325c;
  text-transform: capitalize;
}

.action_btn {
  cursor: pointer;
  color: #2e325c;
  justify-self: end;
}

.transaction_time {
  color: #aaa8b2;
}

.stat_amount {
  font-weight: bold;
}

.deposit_btn,
.withdraw_btn {
  text-align: center !important;
  // border-radius: 0.5rem !important;
  padding: 0.8rem 2.5rem;
  border: none !important;
  outline: none !important;
  color: #fff !important;
  cursor: pointer;
  font-size: 1.2rem !important;
}

.deposit_btn {
  background-color: #3fc1be !important;
}
.withdraw_btn {
  background-color: #404040 !important;
}

.stat_title {
  font-size: 1.2rem;
}

.stat_amount {
  font-size: 1.2rem;
  color: var(--color-primary);
}

.selector {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // border: 1px solid var(--color-grey-dark-3);
  padding: 0.5rem;
  border-radius: 20px;
  background-color: var(--color-dark);
  margin-bottom: 1rem;
  color: var(--color-light);

  &__left {
    flex: 0 0 48%;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    padding: 0.8rem;
    cursor: pointer;
  }

  &__right {
    flex: 0 0 48%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.8rem;
    border-radius: 20px;
    cursor: pointer;
  }
}

.amount_form {
  position: relative;
}

.trx_button {
  position: absolute !important;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px !important;
  width: 40px !important;
  background-color: var(--color-dark) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 1000;
}

.action_card {
  @media (max-width: 576px) {
    margin-top: 2rem !important;
  }
}
