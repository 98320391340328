/* @import '~antd/dist/antd.min.css'; */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

:root {
    --color-primary: #3fc1be;
    --color-light: #fff;
    --color-dark: #2e325c;
    --color-red: rgb(245, 50, 50);
    --color-warning: rgb(252, 166, 38);
    --radius: 20px;

    --color-grey-light-1: #faf9f9;
    --color-grey-light-2: #f8f9fb;
    --color-grey-light-3: #f0eeee;
    --color-grey-light-4: #ccc;

    --color-grey-dark-1: #333;
    --color-grey-dark-2: #777;
    --color-grey-dark-3: #999;

    --background: linear-gradient(14deg, #3fc1be 0%, #3fc1bf6c);
    --color-shadow: 0 7px 14px 0 #3fc1bf49;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

body {
    font-weight: 400 !important;
}

html {
    font-size: 62.5%;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 48em) {
    .container {
        width: 750px;
    }
}

@media (min-width: 62em) {
    .container {
        width: 970px;
    }
}

@media (min-width: 75em) {
    .container {
        width: 1170px;
    }
}

@media (min-width: 87.5em) {
    .container {
        width: 1320px;
    }
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 0;
    height: 0;
}

/* centered pagination style */
.center_pagination {
    margin: 1rem 0;
    display: grid;
    place-items: center;
}

/* input font size to fix zoom issue */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
    font-size: 16px !important;
}