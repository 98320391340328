.logo_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.logo {
    width: 7rem;
}

.link {
    background-color: transparent;
    padding: 1rem;
    color: #2e3158;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;

    &:hover {
        background-color: #fff;
    }
}

.link_active {
    background-color: #f0eeed;
    padding: 1rem;
    border-radius: 1rem;
    color: #2e3158;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.sidebar {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    gap: 1rem;
}

.logout {
    margin-top: auto;
    background-color: transparent;
    padding: 1rem;
    color: #2e3158;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin-bottom: 2rem;
    &:hover {
        background-color: #fff;
        border-radius: 1rem;
    }
}
