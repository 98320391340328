.modal_head {
    margin-bottom: 0;

    @media (max-width: 24.0625em) {
        font-size: medium;
    }

    @media (max-width: 20.9375em) {
        font-size: small;
    }
}
