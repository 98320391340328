.forgot_container {
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, #2d2d2d 0%, rgba(45, 45, 45, 0.8) 100%);
    background-position: center center;
    display: grid;
    place-items: center;
}

.forgot_content {
    background-color: #fff;
    max-width: 850px;
    margin-inline: auto;
    border-radius: 1rem;
}

.forgot_right_img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
}

.forgot_left {
    padding: 2rem;
    height: 100%;
}

.logo_container {
    display: grid;
    place-items: center;
}

.logo {
    width: 8rem;
    height: 8rem;
}

.forgot_form {
    margin-top: 1rem;
}

.form_title {
    text-align: center;
    font-size: 1.8rem;
}

.bottom_links {
    display: flex;
    justify-content: space-between;

    a {
        color: #9ca8b3;
    }
}

.form_content_title {
    margin-bottom: 2rem;
}

// forgot success UI with animation
.forgotSuccess {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2.5rem;
}

// tick mark animation
.animatedTick {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 60px;
        display: block;
    }

    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
    }

    .path.circle {
        -webkit-animation: dash 0.9s ease-in-out;
        animation: dash 0.9s ease-in-out;
    }

    .path.check {
        stroke-dashoffset: -100;
        -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
        animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}
