.notFound {
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: #1a202ce7;
}

.notFound_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.notFound_texts {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.notFound_404,
.notFound_helper {
    color: #a0aec0;
    font-size: x-large;
}

.divider {
    height: 3.5rem !important;
    border-left: 1px solid #ffffff !important;
}

.get_started_btn {
    padding: 1rem 3rem;
    background-color: #404040;
    border-radius: 0.7rem;
    color: #fff;
    &:hover {
        color: #fff;
    }
}
