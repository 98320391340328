$xs: 36em;

.content_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0 0;
}

.logo {
    width: 9rem;

    @media (max-width: $xs) {
        width: 7rem;
    }
}

.right_links {
    display: flex;
    gap: 2rem;

    @media (max-width: $xs) {
        display: none;
    }

    a {
        color: var(--color-light);
        &:hover {
            color: var(--color-light);
        }
    }
}

.register {
    text-align: center;
    background-color: #3fc1be;
    border-radius: 0.8rem;
    padding: 1rem 2.5rem;
}

.login {
    text-align: center;
    background-color: #404040;
    border-radius: 0.8rem;
    padding: 1rem 2.5rem;
}

.mobile_hamburger {
    cursor: pointer;
    @media (min-width: $xs) {
        display: none;
    }
}
