.calculator {
    display: grid;
    place-items: center;
    max-width: 50rem;
    margin-inline: auto;
    margin-top: 2rem;
}

.item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0.3rem;
    background-color: var(--color-grey-light-3);
    margin-bottom: 2rem;
    padding: 1rem 2rem;
    border-radius: 20px;
}

.item_title,
.item_value {
    font-size: 1.5rem;
}
.item_value,
.item_input {
    justify-self: end;
}

.item_value {
    color: var(--color-primary);
}
