.picture_management {
    padding: 2rem;
    box-shadow: 0px 0px 5px 0px rgba(128, 128, 128, 0.41);
    border-radius: 1rem;
}

.verify_info {
    padding: 1rem;
    box-shadow: 0px 0px 5px 0px rgba(128, 128, 128, 0.41);
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media (max-width: 36em) {
        flex-direction: column;
        text-align: center;
    }
}

.picture_title {
    padding-bottom: 1rem;
    text-transform: uppercase;
}
