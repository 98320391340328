.register_page_container {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(90deg, #2d2d2d 0%, rgba(45, 45, 45, 0.8) 100%);
    background-position: center center;
    display: grid;
    place-items: center;
}

.register_content {
    background-color: #fff;
    max-width: 800px;
    margin-inline: auto;
    border-radius: 1rem;
}

.register_left {
    padding: 2rem;
    max-height: 95vh;
    overflow-y: scroll;
}

.logo_container {
    display: grid;
    place-items: center;
}

.logo {
    width: 9rem;
    display: flex;
    justify-content: center;
}

.register_form {
    margin-top: 1rem;
}

.form_title {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.bottom_links {
    display: flex;
    justify-content: center;

    a {
        color: #9ca8b3;
    }
}

.terms_btn {
    margin-left: 0.4rem;
    color: #1890ff;
    cursor: pointer;
}

.reg_already {
    display: block;
    margin: 20px 0;
    text-align: center;
    color: var(--color-grey-dark-2);
}
