$xs: 36em;

.landing_nav_container {
  height: 10vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.landing_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0 0;
}

.logo {
  width: 9rem;
  @media (max-width: $xs) {
    width: 8rem;
  }
}

.middle_links {
  display: flex;
  gap: 4rem;

  @media (max-width: $xs) {
    display: none;
  }

  a {
    color: var(--color-light);
    font-size: 1.4rem;
  }
}

.mobile_hamburger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
    margin-top: 15px;
  @media (min-width: $xs) {
    display: none;

  }
}

.right_links {
  display: flex;
  gap: 2rem;

  @media (max-width: $xs) {
    display: none;
  }

  a {
    color: var(--color-light);

    &:hover {
      color: var(--color-light);
    }
  }
}

.register {
  text-align: center;
  background-color: #3fc1be;
  border-radius: 0.8rem;
  padding: 1rem 2.5rem;
}

.login {
  text-align: center;
  background-color: #404040;
  border-radius: 0.8rem;
  padding: 1rem 2.5rem;
}

.constitution {
  text-align: center;
  color: var(--color-primary) !important;
  // border: 1px solid var(--color-primary);
  background-color: transparent;
  border-radius: 0.8rem;
  padding: 1rem 2.5rem;
}