.dashboard_layout {
    padding: 2rem 3rem;
    background: #efedecee;
    width: 100%;
    height: 100vh;

    @media(max-width: 576px) {
        padding: 2rem 1.5rem;
    }
}

.logo {
    width: 9rem;
    @media (max-width: 36em) {
        width: 7rem;
    }
}

.layout_top_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @media (max-width: 36em) {
        justify-content: flex-end;
    }
}

.layout_main {
    padding-top: 2rem;
}

.layout_children {
    background-color: #fff;
    border-radius: 2rem;
    padding: 2rem;
    height: 80vh;
    overflow-y: scroll;

    @media(max-width: 576px) {
        height: calc(100vh - 100px);
    }
}

.link {
    background-color: transparent;
    padding: 1rem;
    color: #2e3158;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;

    &:hover {
        background-color: #fff;
    }
}

.link_active {
    background-color: #fff;
    padding: 1rem;
    border-radius: 1rem;
    color: #2e3158;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.sidebar {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    gap: 1rem;
}

.logout {
    margin-top: auto;
    background-color: transparent;
    padding: 1rem;
    color: #2e3158;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    &:hover {
        background-color: #fff;
        border-radius: 1rem;
    }
}

.user_data {
    display: flex;
    flex-direction: column;

    @media (max-width: 36em) {
        display: none;
    }
}

.user_name {
    display: flex;
    align-items: center;
}

.user_phone {
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
}

.mobile_hamburger {
    margin-top: 0.9rem;
}
