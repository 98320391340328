$xs: 36em;

.landing_hero_container {
  background: linear-gradient(90deg, #2d2d2d 0%, rgba(45, 45, 45, 0.8) 100%);
  height: 100vh;

  @media(max-width: 576px) {
    background-image: url('../../../public//azima-club-hero.png');
    background-position: bottom;
    background-repeat: no-repeat;
  }
}

.mobile_shade {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #2d2d2d 0%, rgba(45, 45, 45, 0.8) 100%);
  display: none;

  @media(max-width: 576px) {
    display: flex;
  }

}

.landing_hero_content {
  padding-top: 25vh;
  display: flex;
  flex-direction: column;
  height: 100vh;

  padding-bottom: 15vh;
  position: relative;

  @media (max-width: $xs) {
    padding-top: 20vh;
    padding-bottom: 15vh;
  }

  @media (max-width: 18.4375em) {
    padding-bottom: 5vh;
  }
}

.lading_title {
  font-size: 5rem;
  color: #fff;
  font-weight: bold;

  @media (max-width: 21.875em) {
    font-size: 3.5rem;
  }
}

.landing_subtitle {
  font-size: 2rem;
  color: #fff;

  @media (max-width: 21.875em) {
    font-size: 1.5rem;
  }
}

.landing_cta {
  margin-top: 3rem;

  @media(max-width: 576px) {
    width: 100%;
    display: flex;
    align-items: center;

    a {
      height: 50px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    //justify-content: space-between;
    //
    //a {
    //    flex: 0 0 48%;

    //}
  }

  @media (max-width: 21.875em) {
    margin-top: 2rem;
  }

  a {
    color: #fff;
  }
}

.get_started_btn {
  padding: 1rem 3rem;
  background-color: var(--color-primary);
  border-radius: 0.7rem;
  color: var(--color-light);

  &:hover {
    color: var(--color-light);
  }
}

.stat_section {
  margin-top: auto;
  display: flex;
  column-gap: 4rem;
  align-items: center;
  z-index: 2;

  @media (max-width: $xs) {
    flex-wrap: wrap;
    row-gap: 2rem;
    justify-content: space-around;
  }
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat_title {
  //color: #fff;
  color: var(--color-primary);
  font-size: medium;
}

.stat_desc {
  display: flex;
  align-items: center;
}

.stat_count {
  color: #fff;
  font-size: 2.6rem;
}

.mobile_img_container {
  position: absolute;
  right: -7rem;
  bottom: 0rem;

  @media (max-width: $xs) {
    display: none;
  }
}

.mobile_img {
  width: 700px;
  height: 100%;

  @media (max-width: $xs) {
    display: none;
  }
}

.ellipse_img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -5;
  height: 20rem;
}

.ellipse_img_2 {
  position: absolute;
  right: 40rem;
  top: 12rem;
  z-index: -5;
  height: 20rem;
}

.login_btn {
  background-color: #404040;
  margin-left: 1rem;

  @media(min-width: 576px) {
    display: none;
  }
}

.reg_no {
  color: var(--color-light);
  font-weight: 300;
}

.landing_interest {
  color: var(--color-light);
  margin-top: 0.3rem;
  width: 40%;
  z-index: 2;

  @media(max-width: 992px) {
    width: 80%;
  }
  
  @media(max-width: 768px) {
    width: 90%;
  }

  @media(max-width: 576px) {
    width: 100%;
  }

  

  
} 

.landing_interest_2 {
  color: var(--color-light);
  margin-top: 1rem;
  z-index: 2;
} 

.download_app_btn {
  font-size: 1.6rem;
  background: var(--color-primary);
  color: var(--color-light);
  padding: 0.7rem 1rem;
  border-radius: 0.7rem;

  &:hover {
    color: var(--color-light);
  }
}


.footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;


  &__body {
    display: flex;
    justify-content: space-between;
    width: 60%;

     @media(max-width: 576px) {
      width: 100%;
    }
  }

  &__item {
    flex: 0 0 45%;
    flex-direction: column;

    @media(max-width: 576px) {
      flex:  0 0 40%;
      font-size: 10px;
    }

    a {
      color: var(--color-light);
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  b {
    color: var(--color-primary);
    font-size: 16px;
    margin-bottom: 1rem;

    @media(max-width: 576px) {
      font-size: 12px;
      margin-bottom: .5rem;
    }
  }

  div {
    display: flex;
    //flex:  0 0 45%;

    span {
      color: var(--color-light);
      font-weight: 300;
    }
  }
}