.primary_links {
    background-color: #444444e0;
    width: 100%;
    padding: 1rem;
    text-align: center;
    border-radius: 7px;
    margin-bottom: 1rem;

    a {
        color: var(--color-light);
    }
}

.logo_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.logo {
    width: 7rem;
}

.auth_links {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    a {
        color: var(--color-light);
        &:hover {
            color: var(--color-light);
        }
    }
}

.register {
    text-align: center;
    background-color: #3fc1be;
    border-radius: 0.8rem;
    padding: 1rem 2.5rem;
    width: 100%;
}

.login {
    text-align: center;
    background-color: #404040;
    border-radius: 0.8rem;
    padding: 1rem 2.5rem;
    width: 100%;
}
